import { v4 as uuid } from "uuid";

const {
  fileTypes,
  ignoreFormTypeInResponse,
  formTypes,
  colors,
} = require("./Lists");

export const getFileType = (fileName) => {
  var path;
  fileTypes.forEach((item) => {
    if (item.dbPath === fileName) path = item.path;
  });
  return path ? "/" + path : "";
};

export const prepareSheetData = (oldState, newState) => {
  console.log("util old state", oldState, newState);
  var arr = [];

  if (oldState.length === 1 && oldState[0].name !== newState.name) {
    arr.push(oldState[0]);
    arr.push(newState);
  } else if (oldState.length !== 1) arr.push(newState);
  else {
    let temp = oldState;
    temp[0].rows = newState.rows;
    return temp;
  }
  // oldState.forEach((item) => {
  //   if (newState.name !== item.name) arr.push(newState);
  //   else arr.push(item);
  // });
  console.log("prepared sheet data", arr);
  return arr;
};

export const isNumber = (text, getMessage) => {
  text += "";
  let test = /^\d+$/.test(text);
  if (getMessage) return test ? null : "Only number is allowed";
  return test;
};

export const findObj = (value, arrJson, key) => {
  if (!value || !key || !arrJson) return -1;
  for (let i = 0; i < arrJson.length; i++) {
    const element = arrJson[i][key];
    if (element === value) return i;
  }
  return -1;
};
export const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;
  console.log("to shuffled ", array);

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  console.log("shuffled array", array);
  return array;
};
export const checkIfRequired = (data, questions) => {
  let json = {};
  questions.forEach((ques) => {
    if (!data[ques.id] && ques.required) json[ques.id] = "Cannot be empty";
  });
  if (Object.keys(json).length === 0) return null;
  else return json;
};
export const validations = (validationList, value) => {
  try {
    if (value)
      for (let index = 0; index < validationList.length; index++) {
        const validationType = validationList[index];
        const validationMethod = getValidationMethod(validationType);
        console.log(validationType, "value", value);

        try {
          const response = validationMethod(value, true);
          if (response) return response;
        } catch (error) {}
      }
  } catch (error) {}
  return false;
};

export const getValidationMethod = (type) => {
  switch (type) {
    case "number":
      return isNumber;
    case "email":
      return isEmail;
    case "phone":
      return isPhone;
    case "mobile":
      return isMobile;
    case "between":
      return isNumberBetween;
    case "range":
      return isDateRange;

    default:
      return;
  }
};

export const isEmail = (value) => {
  let test = /\S+@\S+\.\S+/.test(value);
  return test ? null : "Invalid email id";

  return test;
};

export const isPhone = (value) => {
  let test = isNumber(value) && value.length === 6;

  return test ? null : "Phone number should be of length 6";
};

export const isMobile = (value) => {
  let test = isNumber(value) && value.length === 10;
  return test ? null : "Mobile number should be of length 10";
};

export const isNumberBetween = (value) => {
  let test = isNumber(value);
  return test ? null : "Number should be between";
};
export const isDateRange = (value) => {
  let test = true;
  return test ? null : "Date should be between";
};

export const prepareFormResponse = (questions, response) => {
  console.log("received props ques", questions, "res", response);
  let responseCount = [];
  questions.forEach((ques) => {
    if (!ignoreFormTypeInResponse.includes(ques.type)) {
      let option = {};
      ques.options.forEach((op) => {
        if (!formTypes[ques.type].multiple)
          option[ques.type] = {
            optionText: op.optionText,
            response: [],
            responseIds: [],
          };
        else
          option[op.id] = {
            optionText: op.optionText,
            response: [],
            responseIds: [],
          };
      });
      responseCount.push({
        quesId: ques.id,
        questionText: ques.questionText,
        options: option,
        type: ques.type,
      });
    }
  });

  response.forEach((res) => {
    let userResponse = res.response;
    Object.keys(userResponse).forEach((quesId) => {
      addResponseInQuestion(
        responseCount,
        quesId,
        userResponse[quesId],
        res._id
      );
    });
  });
  console.log("initial", responseCount);

  //format options
  let finalResponse = {};
  responseCount.forEach((item) => {
    let option = { optionText: [], optionValue: [], optionIds: [] };
    Object.keys(item.options).forEach((opsId) => {
      let ops = item.options[opsId];
      let arr = ops.response.length === 0 ? [0] : ops.response;
      option.optionValue = [...option.optionValue, ...arr];
      option.optionIds.push(opsId);
      option.optionText.push(ops.optionText);
      if (!formTypes[item.type].isMultiple) {
        option["valueCount"] = groupBy(option.optionValue);
      }
    });
    console.log(item);
    finalResponse[item.quesId] = { ...item, ...option };
  });

  return finalResponse;
};
export function groupBy(arr) {
  return arr.reduce((r, c) => ((r[c] = (r[c] || 0) + 1), r), {});
}
function addResponseInQuestion(
  responseCount,
  quesId,
  responseText,
  responseId
) {
  let index = responseCount.findIndex((item) => item.quesId === quesId);
  try {
    console.log("responseText", responseText, "index", index, "quesId", quesId);

    if (index !== -1) {
      let quesRef = responseCount[index];
      let isMultiple = formTypes[quesRef.type].multiple;

      let opRef;
      if (isMultiple) {
        responseText.split(",").forEach((resId) => {
          opRef = quesRef.options[resId];
          let value = (opRef.response.length === 0 ? 0 : opRef.response[0]) + 1;
          opRef.response = [value++];
          opRef.responseIds.push(responseId);
        });
      } else {
        opRef = quesRef.options[quesRef.type];
        opRef.response.push(responseText);
        opRef.responseIds.push(responseId);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const deleteArrayElement = (array, index) => {
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};

export const getRandomColors = (n) => {
  var result = new Array(n),
    len = colors.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = colors[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

export const groupResponses = (response, questions) => {
  let res = [];
  response.forEach((item) => {
    Object.keys(item.response).forEach((key) => {
      let optionId = item.response[key];
      let quesIndex = questions.findIndex((i) => i.id === key);
      let optionText;
      if (formTypes[questions[quesIndex].type].multiple) {
        let opList = [];
        optionId.split(",").forEach((divId) => {
          let optionIndex = questions[quesIndex].options.findIndex(
            (op) => op.id === divId
          );
          try {
            opList.push(
              questions[quesIndex]["options"][optionIndex].optionText
            );
          } catch (error) {
            opList.push("not found");
          }
        });
        optionText = opList.toString();
      }

      console.log(
        quesIndex,
        optionText,
        optionId,
        formTypes[questions[quesIndex].type].multiple,
        questions[quesIndex].type
      );
      res.push({
        quesId: key,
        optionId: optionId,
        resId: item._id,
        userId: item.userId,
        optionText: optionText,
      });
    });
  });

  var groubedByQuesId = groupByJson(res, "quesId");
  console.log(groubedByQuesId);
  var resFinal = {};

  Object.keys(groubedByQuesId).forEach((quesId) => {
    let quesIndex = questions.findIndex((i) => i.id === quesId);
    let resGrouped = groupByJson(groubedByQuesId[quesId], "optionId");
    resFinal[quesId] = {
      data: resGrouped,
      options: groupByJson(questions[quesIndex].options, "id"),
    };
  });
  return resFinal;
};

export const groupByJson = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const generateUniqueId = () => {
  const unique_id = uuid();
  return unique_id;
  // const chars =
  //   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  // let autoId = "";
  // while (autoId.length < 20) {
  //   const bytes = randomBytes(40);
  //   bytes.forEach((b) => {
  //     // Length of `chars` is 62. We only take bytes between 0 and 62*4-1
  //     // (both inclusive). The value is then evenly mapped to indices of `char`
  //     // via a modulo operation.
  //     const maxValue = 62 * 4 - 1;
  //     if (autoId.length < 20 && b <= maxValue) {
  //       autoId += chars.charAt(b % 62);
  //     }
  //   });
  // }
  // return autoId;
};
