import React from "react";
import { useParams } from "react-router-dom";
import "tailwindcss/tailwind.css";
import "@material-tailwind/react/tailwind.css";
import Sheet from "../components/sheet";
import { useState } from "react";
import { getSession } from "../server/auth";
function Home() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  if (!user) getSession(setUser, true);
  return <>{user ? <Sheet session={user} id={id} /> : <div>Loading</div>}</>;
}

export default Home;
